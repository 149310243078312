import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import { FaEnvelope, FaShoppingCart, FaUser, FaComment } from 'react-icons/fa';
import Notifications from './Notifications';
import api from '../../api/api';

const UserNotificationsManager = () => {
  const jwtToken = localStorage.getItem('JWToken');
  const { userNotification } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [activeTab, setActiveTab] = useState('order'); // Onglet actif par défaut

  useEffect(() => {
    setNotifications(userNotification || []);
  }, [userNotification]);

  // Filtrer les notifications par type
  const orderNotifications = notifications.filter(
    (n) => n.notification.notificationType === 'order'
  );
  const messageNotifications = notifications.filter(
    (n) => n.notification.notificationType === 'message'
  );

  // Vérifier s'il y a des notifications non lues
  const hasUnreadNotifications = notifications.some(
    (n) => !n.notificationIsRead
  );

  const hasUnreadOrderNotifications = orderNotifications.some(
    (n) => !n.notificationIsRead
  );
  const hasUnreadMessageNotifications = messageNotifications.some(
    (n) => !n.notificationIsRead
  );

  // Fonction pour marquer les notifications comme lues
  const markNotificationsAsRead = async (notificationIds) => {
    try {
      const response = await api.markNotificationsAsRead(notificationIds);
      if (response.ok) {
        // Mettre à jour l'état local des notifications
        setNotifications((prevNotifications) =>
          prevNotifications.map((n) =>
            notificationIds.includes(n._id)
              ? { ...n, notificationIsRead: true }
              : n
          )
        );
      } else {
        console.error('Échec lors de la mise à jour des notifications');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des notifications:', error);
    }
  };

  // Fonction pour basculer l'affichage des notifications
  const handleToggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <>
      <div className="ml-4 flex p-0.5 items-center justify-center bg-yellow-500 text-white rounded-full h-12 w-12 hover:bg-yellow-600">
        <div className="relative flex flex-col">
          <div className="flex items-center" onClick={handleToggleNotifications}>
            <FaEnvelope className={`text-black p-2 h-10 w-10 cursor-pointer ${hasUnreadNotifications ? 'ml-3' : ''}`} />
            {hasUnreadNotifications && (
              <span className="relative bottom-2 right-3 h-3 w-3 bg-red-500 rounded-full"></span>
            )}
          </div>
        </div>
      </div>

      {showNotifications && (
        <div className="absolute md:right-10 right-0 left-0 md:left-auto top-20 bg-white rounded-md shadow-lg border-2">
          <div className="flex justify-around border-b p-2">
            <button
              className={`relative px-4 py-2 ${
                activeTab === 'order' ? 'font-bold border-b-2 border-blue-500' : ''
              }`}
              onClick={() => setActiveTab('order')}
            >
              <p className="hidden md:block text-lg text-black font-bold m-2 mx-4">Commandes</p>
              <FaShoppingCart className="block md:hidden text-blue-500 text-xl" />
              {hasUnreadOrderNotifications && (
                <span className="absolute top-0 right-1 md:top-4 md:right-4 h-3 w-3 bg-red-500 rounded-full"></span>
              )}
            </button>
            <button
              className={`relative px-4 py-2 ${
                activeTab === 'message' ? 'font-bold border-b-2 border-blue-500' : ''
              }`}
              onClick={() => setActiveTab('message')}
            >
              <p className="hidden md:block text-lg text-black font-bold m-2 mx-4">Messages</p>
              <FaComment className="block md:hidden text-purple-500 text-xl" />
              {hasUnreadMessageNotifications && (
                <span className="absolute top-0 right-1 md:top-4 md:right-4 h-3 w-3 bg-red-500 rounded-full"></span>
              )}
            </button>
          </div>

          <div className="p-4 bg-[#f4f4f4] rounded-b-md">
            {activeTab === 'order' && (
              <Notifications
                notifications={orderNotifications}
                markAsRead={markNotificationsAsRead}
                type="order"
              />
            )}
            {activeTab === 'message' && (
              <Notifications
                notifications={messageNotifications}
                markAsRead={markNotificationsAsRead}
                type="message"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserNotificationsManager;